import { types } from "utils/Constants";
import api from "@services/AxiosConfig/Config";
import apiV2 from "@services/AxiosConfig/Config/apiv2";
import moment from "moment";

export const patientListQuery = (data: {
  page_size: string;
  page: string;
  search: any;
}) => {
  const jsonData: { [key: string]: any } = {};
  const formatDate = (date: Date) => moment(date).format("YYYY-MM-DD");

  Object.entries(data).forEach(([key, value]) => {
    if (["page_size", "page"].includes(key)) return;

    switch (key) {
      case "diagnoses":
      case "status":
        jsonData[key] = value?.split(",") || [];
        break;
      case "primary staff":
      case "secondary staff":
        jsonData[key.replace(" ", "_")] = value;
        break;
      case "programme":
        jsonData["zone_participant"] = value === "Zone";
        break;
      case "program start date":
        jsonData["verified_at_gte"] = formatDate(value.from);
        jsonData["verified_at_lte"] = formatDate(value.to);
        break;
      default:
        jsonData[key] = value;
    }
  });
  const page_size = data.page_size;
  const page = data.page;
  return api.post(`account/patient/search/`, jsonData, {
    params: {
      page_size,
      page,
    },
  });
};

export const patientProfileQuery = (data: {
  id: string | string[] | undefined;
}) => {
  return api.get(`account/profile/patient/${data.id}/`);
};

export const prescribedMedicationListQuery = (data: {
  id: string | string[] | undefined;
}) => {
  return api.get(`data/medication/prescribed/?user=${data.id}`);
};

export const patientRightDataQuery = (data: {
  id: string | string[] | undefined;
}) => {
  return api.get(`data/latest/${data.id}/`);
};

export const fetchDoctorQuery = (data: {
  type?: string;
  patient_user: string;
}) => {
  const type = data.type;
  const patient_user = data.patient_user;
  return api.get(`account/users/patients/${patient_user}/staffs`, {
    params: {
      type,
    },
  });
};

export const noteCategoryListQuery = () => {
  return api.get(`data/note/category/`);
};

export const noteListQuery = (data: {
  page_size: string;
  page: string;
  user: string;
  start_datetime: string;
  end_datetime: string;
  note: string;
}) => {
  const { page_size, page, start_datetime, end_datetime, note, user } = data;
  return api.get(`data/note/`, {
    params: {
      page_size,
      page,
      start_datetime,
      end_datetime,
      note,
      user,
    },
  });
};

export const noteCreateQuery = (data: { note: string; user: string }) => {
  const formData = new FormData();
  formData.append("note", data.note);
  formData.append("user", data.user);
  return api.post(`data/note/`, formData);
};

export const fetchDeviceQuery = (data: { user: number }) => {
  return api.get(`data/device/panel/?user=${data.user}`);
};

export const fileRecordListQuery = (data: {
  page_size: number;
  page: string;
  user: string;
  filename: string;
  start_datetime: string;
  end_datetime: string;
}) => {
  return api.get(
    `data/file/record/?page_size=${data.page_size}&page=${data.page}&start_datetime=${data.start_datetime}&end_datetime=${data.end_datetime}&filename=${data.filename}&user=${data.user}`
  );
};

export const fetchPatientWeightGraph = (data: {
  user: string;
  period: string;
  annotation: number;
  annotation_in_period: number;
}) => {
  const type = types.weight;
  return api.get(`data/device/${type}/chart/weight/`, { params: data });
};

export const fetchPatientHeartRate = (data: {
  user: string;
  period: string;
  annotation: number;
  annotation_in_period: number;
  device_id: number;
}) => {
  return api.get(`data/device/${data.device_id}/chart/heart_rate/`, {
    params: data,
  });
};

export const fetchPatientStep = (data: {
  user: string;
  period: string;
  annotation: number;
  annotation_in_period: number;
  device_id: number;
  start_datetime: Date;
  end_datetime: Date;
}) => {
  return api.get(`data/device/${data.device_id}/chart/steps/`, {
    params: data,
  });
};

export const fetchPatientSleep = (data: {
  user: string;
  period: string;
  annotation: number;
  annotation_in_period: number;
  device_id: number;
  start_datetime: Date;
  end_datetime: Date;
}) => {
  let sleepType = "sleep_stage";

  return api.get(`data/device/${data.device_id}/chart/${sleepType}/`, {
    params: data,
  });
};

export const fetchPatientSleepHistory = (data: {
  user: string;
  device_id: number;
  start_datetime: Date;
  end_datetime: Date;
}) => {
  let sleepType;
  data.device_id === 1
    ? (sleepType = "sleep_asleep")
    : (sleepType = "sleep_in_bed");

  return api.get(`data/device/${data.device_id}/chart/${sleepType}/`, {
    params: data,
  });
};

export const fetchPatientInsulin = (data: {
  user: string;
  period: string;
  annotation: number;
  annotation_in_period: number;
}) => {
  return api.get(`data/medication/category/insulin/chart/`, { params: data });
};

export const fileRecordCreateQuery = (data: {
  is_showable: boolean;
  file: File;
  note: string;
  user: string;
  recorded_at: string;
}) => {
  const formData = new FormData();
  formData.append("is_showable", JSON.stringify(data.is_showable));
  formData.append("file", data.file);
  formData.append("note", data.note);
  formData.append("user", data.user);
  if (data.recorded_at != "") {
    formData.append("recorded_at", data.recorded_at);
  }
  return api.post(`data/file/record/`, formData);
};

export const labTestListQuery = () => {
  return api.get(`data/test/`);
};

export const testCategoriesQuery = (data: { user: number }) => {
  return api.get(`data/test/category/?user=${data.user}`);
};

export const testCategoriesHistoryQuery = (data: {
  user: string;
  cardId: string;
  start_datetime: string;
  end_datetime: string;
}) => {
  return api.get(
    `data/test/category/${data.cardId}/?start_datetime=${data.start_datetime}&end_datetime=${data.end_datetime}&user=${data.user}`
  );
};

export const testRecordCreateQuery = (data: {
  is_showable: boolean;
  file: File;
  description: string;
  user: string;
  data: object;
  id: string;
  recorded_at: string;
}) => {
  const formData = new FormData();
  formData.append("is_showable", JSON.stringify(data.is_showable));
  if (data.file !== null) {
    formData.append("file", data.file);
  }
  formData.append("description", data.description);
  formData.append("user", data.user);
  formData.append("recorded_at", data.recorded_at);

  formData.append("data", JSON.stringify(data.data));
  return api.post(`data/test/${data.id}/record/`, formData);
};

export const testRecordListQuery = (data: {
  page_size: string;
  page: string;
  user: string;
  search: string;
  start_datetime: string;
  end_datetime: string;
  test: string;
}) => {
  return api.get(
    `data/test/record/?user=${data.user}&search=${data.search}&page=${data.page}&page_size=${data.page_size}&start_datetime=${data.start_datetime}&test=${data.test}&end_datetime=${data.end_datetime}`
  );
};

export const fetchBloodPressureGraph = (data: {
  user: string;
  period: string;
  annotation: number;
  annotation_in_period: number;
}) => {
  const type = types.bloodPressure;
  return api.get(`data/device/${type}/chart/`, { params: data });
};

export const fetchGlucoseGraph = (data: {
  user: string;
  period: string;
  annotation: number;
  annotation_in_period: number;
}) => {
  const type = types.glucose;
  return api.get(`data/device/${type}/chart/glucose/`, { params: data });
};

export const fetchFoodLogGraph = (data: {
  user: string;
  period: string;
  annotation: number;
  annotation_in_period: number;
  no_paginate: boolean;
}) => {
  return apiV2.get(`data/food/record/`, {
    params: data,
  });
};

export const addPatientQuery = (data: {
  phone_number: string;
  clinic_no: string;
  first_name: string;
  last_name: string;
  birth_date: string;
  diagnoses: { id: number; value: string; label: string }[];
  medications: { id: number; value: string; label: string }[];
  primary_staff_users: {
    value: string;
    label: string;
    img: string;
    user: number;
    id: number;
  }[];
  secondary_staff_users: {
    value: string;
    label: string;
    img: string;
    user: number;
    id: number;
  }[];
  gender: string;
  nationality?: {
    value: string;
    label: string;
  };
  identity_no: string;
  passport_no: string;
  insurance_no: string;
  insurance_company: { id: number; value: string; label: string } | "";
  location?: {
    value: string;
    label: string;
  };
  // identity_card: File | null;
  email: string;
}) => {
  const formData = new FormData();
  formData.append("phone_number", data.phone_number);
  formData.append("clinic_no", data.clinic_no);
  formData.append("first_name", data.first_name);
  formData.append("last_name", data.last_name);
  formData.append("birth_date", data.birth_date);
  formData.append("gender", data.gender);
  if (data.nationality) {
    formData.append("nationality", data.nationality.value);
  }
  if (data.location) {
    formData.append("location", data.location.value);
  }
  formData.append("identity_no", data.identity_no);
  formData.append("passport_no", data.passport_no);
  formData.append("insurance_no", data.insurance_no);
  if (data.insurance_company != "") {
    formData.append("insurance_company", data.insurance_company.id.toString());
  }
  // formData.append("identity_card", data.identity_card || "");
  formData.append("email", data.email);

  data.medications.map((item: { id: number; value: string; label: string }) => {
    formData.append("medications", item.id.toString());
  });
  data?.diagnoses &&
    data?.diagnoses?.map((item: { id: number }) => {
      formData.append("diagnoses", item.id.toString());
    });
  data.secondary_staff_users.map(
    (item: {
      value: string;
      label: string;
      img: string;
      user: number;
      id: number;
    }) => {
      formData.append("secondary_staff_users", item.user.toString());
    }
  );
  data.primary_staff_users.map(
    (item: {
      value: string;
      label: string;
      img: string;
      user: number;
      id: number;
    }) => {
      formData.append("primary_staff_users", item.user.toString());
    }
  );
  return api.post(`account/invite/patient/`, formData);
};

export const addCampaignQuery = (data: {
  name: string;
  sender_user_id: any[];
  message: string;
  attachment?: File;
  criteria?: { field: string; value: any }[];
}) => {
  const formData = new FormData();
  formData.append("sender_user_id", data?.sender_user_id[0]?.user);
  if (data.attachment) {
    formData.append("attachment", data?.attachment);
  }
  formData.append("message", data?.message);
  formData.append("name", data?.name);
  const filter: any = {};
  data.criteria?.map((item: any) => {
    if (item.field === "primary staff") {
      filter["primary_staff"] = item.value;
    } else if (item.field === "secondary staff") {
      filter["secondary_staff"] = item.value;
    } else if (item.field === "programme") {
      filter["zone_participant"] = item.value === "Zone";
    } else {
      return (filter[item.field] = item.value);
    }
  });
  formData.append("filters", JSON.stringify(filter));
  return api.post(`chat/campaign/`, formData);
};

export const editPatientQuery = (data: {
  user: number;
  phone_number: string;
  clinic_no: string;
  first_name: string;
  last_name: string;
  birth_date: string;
  diagnoses: { id: number; value: string; label: string }[];
  medications: { id: number; value: string; label: string }[];
  primary_staff_users: {
    value: string;
    label: string;
    img: string;
    user: number;
    id: number;
  }[];
  secondary_staff_users: {
    value: string;
    label: string;
    img: string;
    user: number;
    id: number;
  }[];
  gender: string;
  nationality: {
    value: string;
    label: string;
  };
  identity_no: string;
  passport_no: string;
  insurance_company: { id: number; value: string; label: string } | "";
  location: {
    value: string;
    label: string;
  };
  // identity_card: File | null;
  email: string;
  insurance_no: string;
}) => {
  const {
    phone_number,
    clinic_no,
    first_name,
    last_name,
    birth_date,
    gender,
    nationality,
    identity_no,
    passport_no,
    insurance_no,
    location,
    email,
  } = data;
  const diagnoses = data.diagnoses
    ? data.diagnoses.map((data) => data.id)
    : undefined;
  const insurance_company = data.insurance_company
    ? data.insurance_company.id
    : undefined;
  const medications = data.medications?.map((item: { id: number }) => item.id);
  const secondary_staff_users = data.secondary_staff_users?.map(
    (item: { user: number }) => item.user
  );
  const primary_staff_users = data.primary_staff_users?.map(
    (item: { user: number }) => item.user
  );
  return api.patch(`account/profile/patient/${data.user}/`, {
    phone_number,
    clinic_no,
    first_name,
    last_name,
    birth_date,
    diagnoses,
    primary_staff_users,
    gender,
    nationality: nationality.value,
    identity_no,
    passport_no,
    insurance_no,
    insurance_company,
    location: location.value,
    email,
    medications,
    secondary_staff_users,
  });
};

export const diagnosisListQuery = (search?: string) => {
  return api.get(`account/diagnosis/`, {
    params: { search },
  });
};

export const medicationListQuery = (search?: string) => {
  return api.get(`data/medication/`, {
    params: { search },
  });
};

export const insuranceListQuery = () => {
  return api.get(`account/insurance-company/`);
};

export const getCountryList = () => {
  return api.get(`account/country/`);
};
export const getContentTagList = () => {
  return api.get(`common/content-tag/`);
};

export const handleStatusToggleQuery = (data: {
  id: number;
  status: string;
}) => {
  return api.get(`account/${data.id}/${data.status}/`);
};

export const reassignDoctorQuery = (
  data: {
    id: number;
    patient_staff_relationship: "primary" | "secondary";
  }[],
  patientId: string
) => {
  return api.put(`account/users/patients/${patientId}/staffs/`, data);
};

export const getLabTestRecordChartQuery = (data: {
  id: number;
  name: string;
  user: string;
  start_datetime: string;
  end_datetime: string;
}) => {
  return api.get(
    `data/test/${data.id}/chart/${data.name}/?start_datetime=${data.start_datetime}&end_datetime=${data.end_datetime}&user=${data.user}`
  );
};

export const rightManualRecordDataQuery = (data: {
  manual_type: string;
  page: string;
  page_size: string;
  user: string;
}) => {
  return api.get(`data/manual/record/`, {
    params: data,
  });
};

export const rightDeviceRecordDataQuery = (data: {
  page: string;
  page_size: string;
  user: string;
  device_type: string;
  data_type: string;
}) => {
  return api.get(
    `data/device/${data.device_type}/record/${data.data_type}/?page=${data.page}&page_size=${data.page_size}&user=${data.user}`
  );
};

export const rightMedicationRecordDataQuery = (data: {
  page: string;
  page_size: string;
  user: string;
  medication_type: string;
}) => {
  return api.get(
    `data/medication/${data.medication_type}/record/?page=${data.page}&page_size=${data.page_size}&user=${data.user}`
  );
};

export const rightFoodRecordDataQuery = (data: {
  page: string;
  page_size: string;
  user: string;
}) => {
  return apiV2.get(`data/food/record/`, {
    params: data,
  });
};

export const rightStressRecordDataQuery = (data: {
  user: string;
  start_datetime: Date;
  end_datetime: Date;
}) => {
  return api.get(`data/device/8/chart/stress`, {
    params: data,
  });
};

export const updateReadinessStatusQuery = (data: {
  user: string;
  readiness: string;
}) => {
  const formData = new FormData();
  formData.append("user", data.user);
  formData.append("readiness", data.readiness);
  return api.patch(`account/patient/readiness/`, formData);
};

export const updateContentTagQuery = (data: { user: string; tag: string }) => {
  const formData = new FormData();
  formData.append("user", data.user);
  formData.append("content_tag", data.tag);
  return api.patch(`account/patient/content-tag/`, formData);
};
