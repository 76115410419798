import { configureAxiosSurveyUrl } from "./initUrl";
import axios from "axios";
import { configureAxiosDefaultHeaders } from "./initHeader";
import { configureAxiosDefaultSurveyHeaders } from "@services/AxiosConfig/Config/initSurveyHeader";
import { AmplitudeEventType } from "@services/Amplitude/event.constants";
import * as amplitude from "@amplitude/analytics-browser";

const env = process.env.ENVIRONMENT;

if (env === "production") {
  console.log = console.warn = console.error = () => {};
  console.clear();
}

const surveyApi = axios.create({
  baseURL: configureAxiosSurveyUrl(),
  headers: configureAxiosDefaultSurveyHeaders(),
});

export default surveyApi;
