import { COMPLETE, ERRORS, GET_CHAT_CATEGORY } from "./action";

const initState: any = {
  loading: false,
  success: false,
  errors: {},
  categoryList: [],
};

const chat = (state = initState, action: any) => {
  switch (action.type) {
    case GET_CHAT_CATEGORY:
      return {
        ...state,
        success: true,
        categoryList: action.payload,
      };
    case ERRORS:
      return {
        ...state,
        errors: action.payload,
        success: false,
        loading: false,
      };
    case COMPLETE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default chat;
