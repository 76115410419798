import { configureAxiosUrlV2 } from "./initUrl";
import axios from "axios";
import { configureAxiosDefaultHeaders } from "./initHeader";
import { AmplitudeEventType } from "@services/Amplitude/event.constants";
import * as amplitude from "@amplitude/analytics-browser";
const env = process.env.ENVIRONMENT;

if (env === "production") {
  console.log = console.warn = console.error = () => {};
  console.clear();
}
const apiV2 = axios.create({
  baseURL: configureAxiosUrlV2(),
  headers: configureAxiosDefaultHeaders(),
});

export default apiV2;
