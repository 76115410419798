import { KeysEnum } from "@utils/types";

export interface UserInformation {
  id: number;
  avatar: string;
  unread_notifications: number;
  created: Date;
  modified: Date;
  medical_no: null | number;
  gender: "male" | "female" | "other";
  user: number;
  invited_by: number;
  first_name: string;
  last_name: string;
  phone_number: string;
  job_title: JobTitleInfo;
  patients: Patients[];
  email: string;
  status: string;
  timezone: string;
  role: UserRole.Coach | UserRole.Admin | UserRole.Physician;
  verified_at: Date;
  last_active_at: Date;
}

interface JobTitleInfo {
  id: string;
  created: Date;
  modified: Date;
  name: string;
  is_active: boolean;
}

interface Patients {
  id: number;
  last_login: null | string;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  is_active: boolean;
  date_joined: Date;
  created: Date;
  modified: Date;
  phone_number: string;
  verified_at: null | Date;
  role: number;
  accepted_tc_at: null | Date;
  status: number;
  last_active_at: null | Date;
  timezone: string;
  groups: string[];
  user_permissions: string[];
  medications: number[];
  avatar: null | string;
}

export enum UserRole {
  Admin = "admin",
  Physician = "physician",
  Coach = "coach",
}

//state schema
export interface UserState {
  user: KeysEnum<UserInformation>;
}

//action and action types
export const SAVE_USER_DTO = "SAVE_USER_DTO";

interface SaveUserDto {
  type: typeof SAVE_USER_DTO;
  payload: KeysEnum<UserInformation>;
}

export type UserActionType = SaveUserDto;
