import { AppDispatch } from "redux/store";
import {
  callLogOut,
  callRefreshToken,
  checkResponse,
  formatError,
} from "utils";
import { getImageQuery } from "./query";

export const ERRORS = "user/errors";
export const COMPLETE = "COMPLETE";
export const GET_IMAGE = "user/get_image";
export const GET_STAFF_IMAGE = "GET_STAFF_IMAGE";
export const RESET_IMAGE = "RESET_IMAGE";

export const getImage =
  (data: { url: string; id: string; type: string; itemId?: number }) =>
  async (dispatch: any) => {
    const res = await getImageQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: GET_IMAGE,
        payload: {
          response: response,
          id: data.id,
          type: data.type,
          itemId: data.itemId,
        },
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };

export const getStaffImage =
  (data: { url: string; id: string; type: string; itemId?: number }) =>
  async (dispatch: any) => {
    const res = await getImageQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: GET_STAFF_IMAGE,
        payload: {
          response: response,
          id: data.id,
          type: data.type,
          itemId: data.itemId,
        },
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };

export const resetImage = () => async (dispatch: AppDispatch) => {
  dispatch({
    type: RESET_IMAGE,
  });
  dispatch({
    type: COMPLETE,
    payload: "",
  });
};
