import {
  callLogOut,
  callRefreshToken,
  checkResponse,
  formatError,
} from "utils";
import { getDiagnosesQuery } from "./query";

export const ERRORS = "user/errors";
export const COMPLETE = "COMPLETE";
export const GET_DIAGNOSIS = "user/get_diagnosis";

export const getDiagnoses = () => async (dispatch: any) => {
  const res = await getDiagnosesQuery();
  const response = await checkResponse(res);
  if (response.success) {
    dispatch({
      type: GET_DIAGNOSIS,
      payload: response.data,
    });
  }
  dispatch({
    type: COMPLETE,
    payload: "",
  });
};
