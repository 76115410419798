import {
  COMPLETE,
  ERRORS,
  ADD_PATIENT_FILTER,
  ACTION_START,
  GET_PATIENT_FILTER,
  UPDATE_PATIENT_FILTER,
  DELETE_PATIENT_FILTER,
} from "./action";

const initState: any = {
  loading: false,
  success: false,
  isFilterAdd: false,
  isFilterUpdate: false,
  isFilterDeleted: false,
  patientFilter: [],
};

const filters = (state = initState, action: any) => {
  switch (action.type) {
    case ACTION_START:
      return {
        ...state,
        loading: true,
        isFilterAdd: false,
        isFilterUpdate: false,
        isFilterDeleted: false,
      };
    case ADD_PATIENT_FILTER:
      return {
        ...state,
        isFilterAdd: true,
        success: true,
        patientFilter: [...state.patientFilter, action.payload],
      };
    case UPDATE_PATIENT_FILTER:
      const filterList = state.patientFilter;
      const updatedIndex = state.patientFilter.findIndex(
        (item: { id: any }) => item.id === action.payload.id
      );
      if (updatedIndex !== -1) {
        filterList[updatedIndex] = action.payload;
      }
      return {
        ...state,
        isFilterUpdate: true,
        success: true,
        patientFilter: filterList,
      };
    case DELETE_PATIENT_FILTER:
      const updatedFilterList = state.patientFilter.filter(
        (item: { id: any }) => item.id !== action.payload
      );

      return {
        ...state,
        isFilterDeleted: true,
        success: true,
        patientFilter: updatedFilterList,
      };
    case GET_PATIENT_FILTER:
      return {
        ...state,
        patientFilter: action.payload,
        success: true,
      };
    case ERRORS:
      return {
        ...state,
        errors: action.payload,
        success: false,
        loading: false,
      };
    case COMPLETE:
      return { ...state, loading: false, success: false };
    default:
      return state;
  }
};

export default filters;
