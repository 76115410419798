import { UserState, SAVE_USER_DTO, UserActionType } from "./types";

const initialState: UserState = {
  user: {
    avatar: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
  },
};

export function userReducer(
  state = initialState,
  action: UserActionType
): UserState {
  if (action.type === SAVE_USER_DTO) {
    return {
      ...state,
      user: {
        ...state.user,
        ...action.payload,
      },
    };
  } else {
    return state;
  }
}
