import api from "@services/AxiosConfig/Config";

export const createPatientsFilterQuery = (data: any) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    return formData.append(key, value as any);
  });
  return api.post(`account/patient/filter/`, formData);
};
export const updatePatientsFilterQuery = (data: any, id: number) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    return formData.append(key, value as any);
  });
  return api.patch(`account/patient/filter/${id}/`, formData);
};
export const deletePatientsFilterQuery = (id: number) => {
  return api.delete(`account/patient/filter/${id}/`);
};

export const getPatientsFilterQuery = () => {
  return api.get(`account/patient/filter/`);
};
