import {
  callLogOut,
  callRefreshToken,
  checkResponse,
  formatError,
  removeCurrentUser,
} from "utils";
import { getProfileQuery, logoutApi, updateProfile } from "./query";
import { AppDispatch } from "redux/store";
import { CHANGE_STATE } from "redux/StateChange/action";
import { UpdateProfileInfo } from "./index.type";
import { SaveUserDto } from "../user/action";
export const ERRORS = "user/errors";
export const COMPLETE = "COMPLETE";
export const IS_LOGGED_OUT = "IS_LOGGED_OUT";
export const GET_PROFILE = "user/get_profile";

export const updateUserProfile =
  (data: UpdateProfileInfo) => async (dispatch: AppDispatch) => {
    dispatch({
      type: CHANGE_STATE,
      payload: "loading",
    });
    const res = await updateProfile(data);
    const response = await checkResponse(res);

    if (response.success) {
      dispatch({
        type: CHANGE_STATE,
        payload: "true",
      });
    } else {
      if (response.code === 403 || response.code === 401) {
        dispatch(updateUserProfile(data));
      }
      return response;
    }
  };

export const logout = () => async (dispatch: AppDispatch) => {
  const res = await logoutApi();
  const response = await checkResponse(res);
  if (response.code === 204 || response.code === 200) {
    removeCurrentUser();
    dispatch({
      type: IS_LOGGED_OUT,
      payload: {},
    });
  }
};

export const getProfile = () => async (dispatch: AppDispatch) => {
  const res = await getProfileQuery();
  const response = await checkResponse(res);
  if (response.success) {
    dispatch({
      type: GET_PROFILE,
      payload: response.data,
    });
    dispatch(SaveUserDto(response.data));
  }
  dispatch({
    type: COMPLETE,
    payload: "",
  });
};
