import {
  ISurveyAction,
  ISurveyState,
  SurveyActionType,
} from "@redux/survey/types";

const initialState: ISurveyState = {
  templates: [],
  surveys: [],
  reports: [],
  patientFilter: {},
  loading: true,
};

export default function survey(
  state: ISurveyState = initialState,
  action: ISurveyAction
) {
  switch (action.type) {
    case SurveyActionType.TEMPLATES:
      return {
        ...state,
        templates: action.payload,
      };
    case SurveyActionType.SURVEYS:
      return {
        ...state,
        surveys: action.payload,
      };
    case SurveyActionType.PATIENT_FILTER:
      return {
        ...state,
        patientFilter: action.payload,
      };
    case SurveyActionType.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SurveyActionType.REPORTS:
      return {
        ...state,
        reports: action.payload,
      };
    default:
      return state;
  }
}
