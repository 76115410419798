import authConfig from "@utils/auth";

export const configureAxiosDefaultSurveyHeaders = () => {
  let token =
    typeof window !== "undefined" &&
    localStorage.getItem(authConfig.storageTokenKeyName);
  if (!token) {
    setTimeout(() => {
      configureAxiosDefaultSurveyHeaders();
    }, 1000);
  }
  const AxiosHeaders = token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : ({} as any);

  return AxiosHeaders;
};
