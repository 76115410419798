import { UserActionType, UserInformation, SAVE_USER_DTO } from "./types";
import { KeysEnum } from "@utils/types";

export function SaveUserDto(
  userDto: KeysEnum<UserInformation>
): UserActionType {
  return {
    type: SAVE_USER_DTO,
    payload: userDto,
  };
}
