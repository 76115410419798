import {
  callLogOut,
  callRefreshToken,
  checkResponse,
  formatError,
} from "utils";
import {
  internalNotificatoinListQuery,
  internalNotificatoinReadAllQuery,
  internalNotificatoinReadQuery,
  settingDisableAllQuery,
  settingEnableAllQuery,
  settingListQuery,
  updateSettingQuery,
} from "./query";
import { handleFirebaseTokenQuery } from "@services/DataAction/init";
import { FirebaseInfo } from "@services/DataAction/init/type";

export const ERRORS = "user/errors";
export const COMPLETE = "COMPLETE";
export const SETTING_LIST = "user/setting_list";
export const UPDATE_SETTING = "user/update_setting";
export const RESET_SETTING = "user/reset_setting";
export const INTERNAL_NOTIFICATION_LIST = "user/internal_notification_list";
export const GET_FCM_TOKEN = "user/get_fcm_token";

export const settingList = () => async (dispatch: any) => {
  const res = await settingListQuery();
  const response = await checkResponse(res);
  if (response.success) {
    dispatch({
      type: SETTING_LIST,
      payload: response,
    });
  }
  dispatch({
    type: COMPLETE,
    payload: "",
  });
};

export const updateSetting =
  (data: { category: string; channel_type: string; action: string }) =>
  async (dispatch: any) => {
    const res = await updateSettingQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };

export const settingEnableAll = () => async (dispatch: any) => {
  const res = await settingEnableAllQuery();
  const response = await checkResponse(res);
  dispatch({
    type: RESET_SETTING,
  });
  dispatch(settingList());
  if (response.success) {
  }
  dispatch({
    type: COMPLETE,
    payload: "",
  });
};

export const settingDisableAll = () => async (dispatch: any) => {
  const res = await settingDisableAllQuery();
  const response = await checkResponse(res);
  dispatch({
    type: RESET_SETTING,
  });
  dispatch(settingList());
  if (response.success) {
  }
  dispatch({
    type: COMPLETE,
    payload: "",
  });
};

export const internal_notification_list =
  (data: { page_size: number; page: number }) => async (dispatch: any) => {
    const res = await internalNotificatoinListQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: INTERNAL_NOTIFICATION_LIST,
        payload: { data: response.data, pagination: response.pagination },
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };

export const internal_notification_read =
  (data: {
    id: number;
    notificationData: {
      created: string;
      id: number;
      modified: string;
      read_at: string;
      text: string;
      title: string;
      user: number;
    }[];
    pagination: {
      count: number;
      next: boolean;
      page: number;
      page_size: number;
      previous: boolean;
      total_pages: number;
    };
  }) =>
  async (dispatch: any) => {
    const res = await internalNotificatoinReadQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: INTERNAL_NOTIFICATION_LIST,
        payload: {
          data: data.notificationData.map(
            (notiData: {
              created: string;
              id: number;
              modified: string;
              read_at: string;
              text: string;
              title: string;
              user: number;
            }) => {
              if (notiData.id == data.id) {
                return { ...notiData, read_at: "read" };
              } else {
                return notiData;
              }
            }
          ),
          pagination: data.pagination,
        },
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };

export const internal_notification_read_all =
  (data: { pageSize: number }) => async (dispatch: any) => {
    const res = await internalNotificatoinReadAllQuery();
    const response = await checkResponse(res);
    if (response.success) {
      dispatch(
        internal_notification_list({
          page_size: data.pageSize,
          page: 1,
        })
      );
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };

export const handleFirebaseToken =
  (data: FirebaseInfo) => async (dispatch: any) => {
    const res = await handleFirebaseTokenQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: GET_FCM_TOKEN,
        payload: false,
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };
