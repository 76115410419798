import {
  callLogOut,
  callRefreshToken,
  checkResponse,
  formatError,
} from "utils";
import {
  createPatientsFilterQuery,
  getPatientsFilterQuery,
  updatePatientsFilterQuery,
  deletePatientsFilterQuery,
} from "./query";
import { toast } from "react-toastify";
import { CHANGE_STATE } from "redux/StateChange/action";
export const ACTION_START = "ACTION_START";
export const ERRORS = "ERROR";
export const COMPLETE = "COMPLETE";
export const ADD_PATIENT_FILTER = "ADD_PATIENT_FILTER";
export const GET_PATIENT_FILTER = "GET_PATIENT_FILTER";
export const UPDATE_PATIENT_FILTER = "UPDATE_PATIENT_FILTER";
export const DELETE_PATIENT_FILTER = "DELETE_PATIENT_FILTER";

export const createPatientsFilter = (data: any) => async (dispatch: any) => {
  dispatch({
    type: CHANGE_STATE,
    payload: "loading",
  });
  dispatch({ type: ACTION_START });
  const res = await createPatientsFilterQuery(data);
  const response = await checkResponse(res);
  if (response.success) {
    dispatch({
      type: ADD_PATIENT_FILTER,
      payload: response.data,
    });
    dispatch({
      type: CHANGE_STATE,
      payload: "true",
    });
  }
};
export const updatePatientsFilter =
  (data: any, id: number) => async (dispatch: any) => {
    dispatch({
      type: CHANGE_STATE,
      payload: "loading",
    });
    dispatch({ type: ACTION_START });
    const res = await updatePatientsFilterQuery(data, id);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: UPDATE_PATIENT_FILTER,
        payload: response.data,
      });
      dispatch({
        type: CHANGE_STATE,
        payload: "true",
      });
    }
  };
export const getPatientsFilter = (data: any) => async (dispatch: any) => {
  dispatch({ type: ACTION_START });
  const res = await getPatientsFilterQuery();
  const response = await checkResponse(res);
  if (response.success) {
    dispatch({
      type: GET_PATIENT_FILTER,
      payload: response.data,
    });
  }
  dispatch({
    type: COMPLETE,
    payload: "",
  });
};

export const deletePatientsFilter = (id: number) => async (dispatch: any) => {
  dispatch({ type: ACTION_START });
  const res = await deletePatientsFilterQuery(id);
  const response = await checkResponse(res);
  if (response.success) {
    dispatch({
      type: DELETE_PATIENT_FILTER,
      payload: id,
    });
    toast.success("Filter deleted successfully.");
  }
  dispatch({
    type: "COMPLETE",
    payload: "",
  });
};
