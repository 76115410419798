import authConfig from "@utils/auth";

export const configureAxiosDefaultHeaders = () => {
  const token =
    typeof window !== "undefined" &&
    localStorage.getItem(authConfig.storageTokenKeyName);
  const AxiosHeaders = token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : ({} as any);

  return AxiosHeaders;
};
