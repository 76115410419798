import { filterPatientType } from "../../pages/survey/send";

export enum SurveyActionType {
  SURVEYS = "survey/getSurvey",
  TEMPLATES = "survey/getTemplates",
  REPORTS = "survey/getReports",
  PATIENT_FILTER = "survey/patientFilter",
  LOADING = "survey/loading",
}

export interface ISurveyState {
  templates: [ITemplate?];
  patientFilter: filterPatientType;
  surveys: [ISurvey?];
  reports?: [string?];
  loading: boolean;
}

export interface ISurvey {
  id: string;
  title: String;
  description: String;
  template: ITemplate;
}

export interface ICreateSurvey {
  title?: string;
  description?: string;
  template_id: number;
  patients_filter?: patientFilter;
  including_patients?: number[] | null;
}

export type searchOperation = "and" | "or" | "like";
export type searchDirection = "desc" | "asc";
export type surveyField =
  | "patient.full_name"
  | "patient.id"
  | "template.id"
  | "template.title"
  | "template.description"
  | "created_at"
  | "submitted_at"
  | "score";

export interface searchValue {
  field: surveyField;
  operation: searchOperation;
  value: number;
}

export interface IReportsFilter {
  operation: searchOperation;
  value: searchValue[];
}

export interface ISort {
  field: string;
  direction: searchDirection;
}

export interface IReportsBody {
  filters: IReportsFilter[];
  sorts: ISort[];
  page: number;
  size: number;
}

export interface ITemplate {
  id: number;
  title: string;
  description: string;
  questions?: [IQuestion];
}

export type QuestionType = "SINGLE_TEXTBOX" | "MULTIPLE_CHOICE";

export interface IQuestion {
  id: number;
  title: string;
  description: string;
  type: QuestionType;
  choices: [IChoice];
}

export interface IChoice {
  id: {
    value: number;
  };
  title: {
    value: string;
  };
  description: {
    value: string;
    weight: {
      value: string;
    };
  };
}

export interface ISurveyAction {
  type: SurveyActionType;
  payload: ISurveyState;
}

export interface patientFilter {
  gender?: number[];
  age?: {
    lt: number;
    gt: number;
  };
  zone_participant?: boolean;
  staff?: number[];
  primary_staff?: number[];
  secondary_staff?: number[];
  status?: [];
  diagnoses?: number[];
}
