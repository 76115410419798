import React from "react";
import {
  callRefreshToken,
  getCurrentUser,
  removeCurrentUser,
} from "@utils/index";
import Router from "next/router";
import api from "../AxiosConfig/Config";
import { refreshTokenApi } from "@redux/Auth/query";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { CHANGE_STATE } from "@redux/StateChange/action";
import { ERRORS } from "@redux/Auth/action";
import apiV2 from "@services/AxiosConfig/Config/apiv2";
import { AmplitudeEventType } from "@services/Amplitude/event.constants";
import * as amplitude from "@amplitude/analytics-browser";
import surveyApi from "@services/AxiosConfig/Config/surveyApi";

const AxiosErrorHandler = () => {
  const dispatch = useDispatch();
  let refreshTokenPromise: Promise<any> | null = null;
  let authTokenRequest: any;

  function getAuthToken() {
    if (!authTokenRequest) {
      authTokenRequest = refreshTokenApi();
      authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest);
    }

    return authTokenRequest;
  }

  function resetAuthTokenRequest() {
    authTokenRequest = null;
  }

  function showError(response: any) {
    if (typeof response === "object" && response.message) {
      if (typeof response.message === "string") {
        toast.error(response.message);
      } else if (Array.isArray(response.message)) {
        response.message?.forEach((msg: string) => toast.error(msg));
      } else {
        for (const key in response.message) {
          if (Array.isArray(response.message[key])) {
            response.message[key]?.forEach((msg: string) => toast.error(msg));
          } else {
            toast.error(response.message[key]);
          }
        }
      }
    } else if (typeof response === "string") {
      toast.error(response);
    } else {
      console.error("Unexpected error response format");
    }
  }

  let isRefreshing = false;
  const handleErrorResponse = async (error: any) => {
    const { response, config } = error;
    const message = response?.data?.message;
    const url = config?.url;

    const event = {
      event_type: AmplitudeEventType.servicesError,
      event_properties: {
        statusCode: error.response.status,
        errorCode: error.code,
        baseURL: error.config.baseURL,
        endPoint: error.config.url,
        message: error.message,
      },
    };

    amplitude.track(event);

    dispatch({ type: CHANGE_STATE, payload: "false" });
    dispatch({ type: ERRORS, payload: response });

    switch (response?.status) {
      case 400:
        if (config?.method === "post") {
          if (url.includes("account/refresh/")) {
            removeCurrentUser();
            Router.push("/");
          }
          showError(response?.data);
          return Promise.reject(response);
        }
        break;

      case 403:
        if (config?.method === "post") {
          toast.info(message);
          return Promise.reject(response);
        }
        break;

      case 500:
        toast.error(
          "Apologies, a server error (500) happened. We’re working to fix it, appreciate your patience."
        );
        break;

      case 401:
        if (url.includes("account/refresh/")) {
          removeCurrentUser();
          Router.push("/");
        }
        if (!error.config.__isRetryRequest) {
          if (!refreshTokenPromise) {
            refreshTokenPromise = callRefreshToken();
          }
          if (!isRefreshing) {
            try {
              await refreshTokenPromise;
              error.config.__isRetryRequest = true;
              return api(error.config);
            } catch (refreshError) {
              throw refreshError;
            } finally {
              isRefreshing = false;
              refreshTokenPromise = null;
            }
          }
        }
        break;

      default:
        break;
    }

    return Promise.reject(response);
  };

  api.interceptors.response.use(
    (response) => response,
    (error) => handleErrorResponse(error)
  );

  apiV2.interceptors.response.use(
    (response) => response,
    (error) => handleErrorResponse(error)
  );

  surveyApi.interceptors.response.use(
    (response) => response,
    (error) => handleErrorResponse(error)
  );

  api.interceptors.request.use(
    async (config) => {
      const { token } = getCurrentUser();
      if (token) {
        config.headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  apiV2.interceptors.request.use(
    async (config) => {
      const { token } = getCurrentUser();
      if (token) {
        config.headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  return <></>;
};

export default AxiosErrorHandler;
