import { createStore, applyMiddleware, Action, Dispatch, compose } from "redux";
import thunk, { ThunkAction } from "redux-thunk";
import rootReducer from "./rootReducer";
const env = process.env.ENVIRONMENT;
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
const checkEnvironment = env === "develop" || env === "localhost";
const composeEnhancers =
  (checkEnvironment &&
    typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

// initial states here
const initalState: any = {};

// middleware
const middleware = [thunk];

const store = createStore(
  rootReducer,
  initalState,
  compose(applyMiddleware(...middleware), composeEnhancers())
);

export type AppDispatch = typeof store.dispatch | Dispatch<any>;
export type CustomDispatch = { type: string; payload: any } | any;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  {},
  null,
  Action<string>
>;
export default store;
