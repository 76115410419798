export const types = {
  heartRate: 1,
  weight: 1,
  insulin: 2,
  bloodPressure: 6,
  glucose: 2,
  foodLog: 2,
};

export const numberOfDays = 20;

export const numberFormatter = (number: number) => {
  return number?.toString().replace(/^[+-]?\d+/, function (int) {
    return int.replace(/(\d)(?=(\d{3})+$)/g, "$1,");
  });
};

export const timeConvert = (num: number) => {
  const hours = Math.floor(num / 60);
  const minutes = num % 60;
  const mm = minutes < 10 ? "0" + minutes : minutes;
  const hh = hours < 10 ? "0" + hours : hours;
  return hh + ":" + mm;
};
