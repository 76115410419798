import { combineReducers } from "redux";
import staffRecord from "./StaffServices/staff.reducer";
import image from "./Image/reducer";
import patient from "./Patient/reducer";
import setting from "./Settings/reducer";
import diagnosis from "./Diagnosis/reducer";
import filters from "./Filters/reducer";
import chat from "./Chat/reducer";
import stateChange from "./StateChange/reducer";
import { userReducer } from "./user/reducer";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import survey from "@redux/survey/reducer";

const rootReducer = combineReducers({
  user: userReducer,
  staffRecord,
  image,
  patient,
  setting,
  diagnosis,
  filters,
  chat,
  stateChange,
  survey,
});

export type RootState = ReturnType<typeof rootReducer>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default rootReducer;
