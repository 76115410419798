import api from "@services/AxiosConfig/Config";
import { FirebaseInfo } from "@services/DataAction/init/type";
import { getBrowser } from "@utils/index";

export const handleFirebaseTokenQuery = (data: FirebaseInfo) => {
  return api.post(`application/installation/`, {
    app: 1, //staff
    code: 1, //version_code
    os: 3, //web,
    device_id: data.device_id,
    brand: `${getBrowser().name} ${getBrowser().version}`,
  });
};
