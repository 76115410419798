import api from "@services/AxiosConfig/Config";

export const getImageQuery = (data: {
  url: string;
  id?: string | number;
  type?: string;
}) => {
  return api.get(`storage/${data.url}`, {
    responseType: "arraybuffer",
  });
};
