import { CHANGE_STATE, CHANGE_STATE_UPLOAD_REPORT } from "./action";

const initState: any = {
  dataState: "",
  uploadReport: false,
  labTest: false,
};

const stateChange = (state = initState, action: any) => {
  switch (action.type) {
    case CHANGE_STATE:
      return {
        ...state,
        dataState: action.payload,
      };
    case CHANGE_STATE_UPLOAD_REPORT:
      return {
        ...state,
        uploadReport: !state.uploadReport,
      };
    default:
      return state;
  }
};

export default stateChange;
