export const GET_USER_DETAIL = "GET_USER_DETAIL";
export const GET_JOB_TITLE_LIST = "GET_JOB_TITLE_LIST";
export const GET_STAFF_LIST = "GET_STAFF_LIST";

export interface IStore {
  auth: any;
  staffRecord: any;
  image: any;
  patient: any;
  chat: any;
  stateChange: any;
}
