import {
  COMPLETE,
  ERRORS,
  FETCH_DEVICE,
  FETCH_DOCTOR,
  FETCH_SECONDARY_DOCTOR,
  FILE_RECORD_LIST,
  HEART_RATE_DATA,
  STEPS,
  SLEEP,
  SLEEP_HISTORY,
  INSULIN_DATA,
  NOTE_CATEGORY_LIST,
  NOTE_LIST,
  PATIENT_LIST,
  PATIENT_PROFILE,
  PATIENT_RIGHT_DATA,
  PRESCRIBED_MEDICATION_LIST,
  RESET_PROFILE,
  SET_PATIENT_ID,
  WEIGHT_DATA,
  TEST_CATEGORIES,
  TEST_CATEGORIES_HISTORY,
  TEST_RECORD_LIST,
  BLOOD_PRESSURE_DATA,
  GLUCOSE_DATA,
  FOODLOG_DATA,
  DIAGNOSIS_LIST,
  MEDICATION_LIST,
  UPDATE_STATUS,
  FETCH_PRIMARY_SECONDARY_DOCTOR,
  FETCH_MEDICATION_LIST,
  LAB_TEST_RECORD_CHART,
  RESET_LAB_TEST_RECORD_CHART,
  INSURANCE_LIST,
  EDIT_PROFILE,
  RIGHT_RECORD_DATA,
  RESET_RIGHT_RECORD_DATA,
  RESET_STEPS_HISTORY,
  RESET_SLEEP_HISTORY,
  UPDATE_READINESS_STATUS,
  LAB_TEST_LIST,
  UPDATE_CONTENT_TAG,
  patientStepHistory,
  STEPS_HISTORY,
  RESET_SLEEP,
  STRESS,
  PATIENT_RIGHT_DATA_LOADING,
} from "./action";
import { SAVE_BOOKMARK, SAVE_QUICK_CHAT } from "@redux/StateChange/action";

export type sleepStageValue = {
  recorded_at: Date;
  value: number;
};

const initState: any = {
  body: [],
  success: false,
  loading: true,
  profile: {},
  errors: {},
  notes: {},
  manual_data: {},
  medical_reports: {},
  test_categories: {},
  test_categories_history: {},
  test_record: {},
  diagnosis_list: [],
  medication_list: [],
  insurance_list: [],
  isStatusUpdated: false,
  doctor: {},
  fetch_medications: [],
  lab_test_record_chart: {},
  fetch_edit: false,
  right_record_data: { data: [], pagination: {} },
  sleep: {
    sleep_asleep_items: {
      records: [],
    },
    sleep_awake_items: {
      records: [],
    },
    sleep_deep_items: {
      records: [],
    },
    sleep_in_bed_items: {
      records: [],
    },
    sleep_light_items: {
      records: [],
    },
    sleep_rem_items: {
      records: [],
    },
  },
  patient_right_data_loading: true,
  sleep_history: { data: [], loadMore: false, loading: false },
  steps: { data: [], loadMore: false },
  steps_history: { data: [], loadMore: false },
  check_readiness: false,
  check_contentTag: false,
  edit_data: {},
  bookmark: "",
};

const patient = (state = initState, action: any) => {
  switch (action.type) {
    case PATIENT_LIST:
      return {
        ...state,
        success: true,
        body: action.payload.data,
        pagination: action.payload.pagination,
      };
    case SAVE_BOOKMARK:
      return {
        ...state,
        bookmark: action.payload,
      };
    case SAVE_QUICK_CHAT:
      return {
        ...state,
        quickChat: action.payload,
      };
    case PATIENT_PROFILE:
      return { ...state, success: true, profile: action.payload.data };
    case PRESCRIBED_MEDICATION_LIST:
      return {
        ...state,
        success: true,
        profile: {
          ...state.profile,
          prescribed_medication_list: action.payload.data,
        },
      };
    case FETCH_MEDICATION_LIST:
      return {
        ...state,
        success: true,
        fetch_medications: action.payload.data,
      };
    case PATIENT_RIGHT_DATA:
      return {
        ...state,
        success: true,
        profile: {
          ...state.profile,
          patient_right_data: { [action.payload.id]: action.payload.data },
        },
      };
    case PATIENT_RIGHT_DATA_LOADING:
      return {
        ...state,
        success: true,
        patient_right_data_loading: action.payload.loading,
      };
    case RIGHT_RECORD_DATA:
      return {
        ...state,
        success: true,
        right_record_data: {
          data: [...state.right_record_data.data, ...action.payload.data],
          pagination: action.payload.pagination,
        },
      };
    case RESET_RIGHT_RECORD_DATA:
      return {
        ...state,
        success: true,
        right_record_data: { data: [], pagination: {} },
      };
    case FETCH_DOCTOR:
      if (action.payload.data.length == 0) {
        return { ...state, success: true, profile: { ...state.profile } };
      } else {
        return {
          ...state,
          success: true,
          profile: {
            ...state.profile,
            fetch_doctor: {
              [action.payload.data[0].patient_user]: action.payload.data,
            },
          },
        };
      }
    case FETCH_SECONDARY_DOCTOR:
      if (action.payload.data.length == 0) {
        return { ...state, success: true, profile: { ...state.profile } };
      } else {
        return {
          ...state,
          success: true,
          profile: {
            ...state.profile,
            fetchSecondaryDoctor: {
              [action.payload.data[0].patient_user]: action.payload.data,
            },
          },
        };
      }
    case FETCH_PRIMARY_SECONDARY_DOCTOR:
      if (action.payload.type == "1") {
        return {
          ...state,
          success: true,
          doctor: {
            ...state.doctor,
            primary_doctor: action.payload.data,
          },
        };
      } else if (action.payload.type == "2") {
        return {
          ...state,
          success: true,
          doctor: {
            ...state.doctor,
            secondary_doctor: action.payload.data,
          },
        };
      } else {
        return {
          ...state,
          success: true,
          doctor: {
            ...state.doctor,
            patient_staff_relationship: action.payload.data,
          },
        };
      }

    case EDIT_PROFILE:
      return {
        ...state,
        success: true,
        edit_data: {
          success: true,
          profile: {
            ...action.payload.profile,
            prescribed_medication_list: action.payload.fetch_medications,
            fetch_doctor: {
              [action.payload.profile?.user]:
                action.payload.fetch_primary_doctor.data,
            },
          },
          fetch_medications: action.payload.fetch_medications,
          doctor: {
            ...state.doctor,
            primary_doctor: action.payload.fetch_primary_doctor.data,
            secondary_doctor: action.payload.fetch_secondary_doctor.data,
          },
          diagnosis_list: action.payload.diagnosis_list,
          medication_list: action.payload.medication_list,
          insurance_list: action.payload.insurance_list,
        },
        profile: {
          ...action.payload.profile,
          prescribed_medication_list: action.payload.fetch_medications,
          fetch_doctor: {
            [action.payload.profile?.user]:
              action.payload.fetch_primary_doctor.data,
          },
        },
        fetch_medications: action.payload.fetch_medications,
        doctor: {
          ...state.doctor,
          primary_doctor: action.payload.fetch_primary_doctor.data,
          secondary_doctor: action.payload.fetch_secondary_doctor.data,
        },
        diagnosis_list: action.payload.diagnosis_list,
        medication_list: action.payload.medication_list,
        insurance_list: action.payload.insurance_list,
        fetch_edit: true,
      };

    case FETCH_DEVICE:
      return {
        ...state,
        success: true,
        profile: { ...state.profile, fetch_device: action.payload.data },
      };
    case RESET_PROFILE:
      return {
        ...state,
        success: true,
        profile: {},
        notes: {},
        medical_reports: {},
        test_categories: {},
        test_categories_history: {},
        test_record: {},
        errors: {},
        lab_test_record_chart: {},
        fetch_edit: false,
        doctor: {},
      };
    case SET_PATIENT_ID:
      return {
        ...state,
        success: true,
        profile: { ...state.profile, id: action.payload.data.id },
      };
    case NOTE_CATEGORY_LIST:
      return {
        ...state,
        success: true,
        notes: { ...state.notes, notes_category_list: [action.payload.data] },
      };
    case NOTE_LIST:
      return {
        ...state,
        success: true,
        notes: {
          ...state.notes,
          notes_list: [action.payload.data],
          pagination: [action.payload.pagination],
        },
      };
    case WEIGHT_DATA:
      return {
        ...state,
        success: true,
        manual_data: action.payload,
      };
    case BLOOD_PRESSURE_DATA:
      return {
        ...state,
        success: true,
        bloodPressureData: action.payload,
      };
    case GLUCOSE_DATA:
      return {
        ...state,
        success: true,
        glucoseData: action.payload,
      };
    case FOODLOG_DATA:
      return {
        ...state,
        success: true,
        foodLogData: action.payload,
      };
    case HEART_RATE_DATA:
      return {
        ...state,
        success: true,
        heartRateData: action.payload,
      };
    case STEPS:
      return {
        ...state,
        success: true,
        steps: {
          data: action?.payload?.data,
          loadMore: action.payload.loadMore,
        },
      };
    case STRESS:
      return {
        ...state,
        success: true,
        stress: {
          data: action?.payload?.data,
          loading: action.payload.loading,
        },
      };
    case STEPS_HISTORY:
      return {
        ...state,
        success: true,
        steps_history: {
          data: [...state?.steps_history?.data, ...action?.payload?.data],
          loadMore: action.payload.loadMore,
        },
      };
    case RESET_STEPS_HISTORY:
      return {
        ...state,
        success: true,
        steps_history: { data: [], loadMore: false },
      };
    case RESET_SLEEP:
      return {
        ...state,
        success: true,
        sleep: {
          sleep_asleep_items: {
            records: [],
          },
          sleep_awake_items: {
            records: [],
          },
          sleep_deep_items: {
            records: [],
          },
          sleep_in_bed_items: {
            records: [],
          },
          sleep_light_items: {
            records: [],
          },
          sleep_rem_items: {
            records: [],
          },
        },
      };
    case SLEEP:
      return {
        ...state,
        success: true,
        sleep: action?.payload.data,
      };
    case SLEEP_HISTORY:
      return {
        ...state,
        success: true,
        sleep_history: {
          data: action?.payload?.data,
          loadMore: action.payload.loadMore,
          loading: action.payload.loading,
        },
      };
    case RESET_SLEEP_HISTORY:
      return {
        ...state,
        success: true,
        sleep_history: { data: [], loadMore: false },
      };
    case INSULIN_DATA:
      return {
        ...state,
        success: true,
        insulinData: action.payload,
      };
    case FILE_RECORD_LIST:
      return {
        ...state,
        success: true,
        medical_reports: {
          ...state.medical_reports,
          medical_reports_list: [action.payload.data],
          pagination: [action.payload.pagination],
        },
      };
    case TEST_CATEGORIES:
      return {
        ...state,
        success: true,
        test_categories: {
          ...state.test_categories,
          test_categories_list: action.payload.data,
        },
      };
    case LAB_TEST_LIST:
      return {
        ...state,
        success: true,
        test_categories: {
          ...state.test_categories,
          lab_test_list: action.payload,
        },
      };
    case TEST_CATEGORIES_HISTORY:
      return {
        ...state,
        success: true,
        test_categories_history: {
          ...state.test_categories_history,
          test_categories_history_list: action.payload.data,
        },
      };
    case LAB_TEST_RECORD_CHART:
      return {
        ...state,
        success: true,
        lab_test_record_chart: {
          ...state.lab_test_record_chart,
          [action.payload.name]: {
            id: action.payload.id,
            data: action.payload.data.items,
          },
        },
      };
    case RESET_LAB_TEST_RECORD_CHART:
      if (action.payload != "removeAll") {
        let record_chart = { ...state.lab_test_record_chart };
        delete record_chart[action.payload];
        return {
          ...state,
          success: true,
          lab_test_record_chart: {
            ...record_chart,
          },
        };
      } else {
        return {
          ...state,
          success: true,
          lab_test_record_chart: {},
        };
      }

    case ERRORS:
      return {
        ...state,
        errors: action.payload,
        success: false,
        loading: false,
      };
    case COMPLETE:
      return { ...state, loading: false, isStatusUpdated: false };
    case TEST_RECORD_LIST:
      return {
        ...state,
        success: true,
        test_record: {
          ...state.test_record,
          test_record_list: action.payload.data,
          pagination: action.payload.pagination,
        },
      };
    case DIAGNOSIS_LIST:
      return {
        ...state,
        success: true,
        diagnosis_list: action.payload,
      };
    case MEDICATION_LIST:
      return {
        ...state,
        success: true,
        medication_list: action.payload,
      };
    case INSURANCE_LIST:
      return {
        ...state,
        success: true,
        insurance_list: action.payload,
      };
    case UPDATE_READINESS_STATUS:
      return {
        ...state,
        success: true,
        check_readiness: action.payload,
      };
    case UPDATE_CONTENT_TAG:
      return {
        ...state,
        success: true,
        check_contentTag: action.payload,
      };
    case UPDATE_STATUS:
      // const patientsList = state.body;
      // const selectedItemIndex =
      //   patientsList &&
      //   patientsList.findIndex((item: any) => item.user === action.payload.id);
      // if (action.payload.status === "verify") {
      //   patientsList[selectedItemIndex].verified_at = moment().toDate();
      // } else {
      //   patientsList[selectedItemIndex].verified_at = null;
      // }
      return {
        ...state,
        success: true,
        isStatusUpdated: true,
      };
    default:
      return state;
  }
};

export default patient;
