import { UpdateProfileInfo } from "./index.type";
import api from "@services/AxiosConfig/Config";

export const changePassword = (data: any) => {
  return api.post(`account/change-password/`, data);
};

export const updateProfile = (data: UpdateProfileInfo) => {
  const formData = new FormData();
  formData.append("first_name", data.first_name);
  formData.append("last_name", data.last_name);
  formData.append("gender", data.gender);
  formData.append("role", data.role);
  formData.append("job_title", data.job_title);
  formData.append("email", data.email);
  if (data.checkChange) {
    formData.append("avatar", data.avatar);
  }
  return api.patch(`account/profile/`, formData);
};

export const refreshTokenApi = () => {
  let token = localStorage.getItem("gluportal/refreshToken");
  const formData = new FormData();
  formData.append("refresh", token || "");
  return api.post(`account/refresh/`, formData);
};

export const logoutApi = () => {
  let token = localStorage.getItem("gluportal/refreshToken");
  return api.post(`account/logout/`, { refresh: token });
};

export const getProfileQuery = () => {
  return api.get(`account/profile/`);
};
