import { configureAxiosUrl } from "./initUrl";
import axios from "axios";
import { configureAxiosDefaultHeaders } from "./initHeader";
import { AmplitudeEventType } from "@services/Amplitude/event.constants";
import * as amplitude from "@amplitude/analytics-browser";

const env = process.env.ENVIRONMENT;

if (env === "production") {
  console.log = console.warn = console.error = () => {};
  console.clear();
}

const api = axios.create({
  baseURL: configureAxiosUrl(),
  headers: configureAxiosDefaultHeaders(),
});

export default api;
