import CryptoJS from "crypto-js";
import { IS_LOGGED_OUT } from "redux/Auth/action";
import { refreshTokenApi } from "redux/Auth/query";
import authConfig from "./auth";
const cryptoSecret = "t6w9z$C&F)J@NcRf";
export const CHAT_API_KEY: string = process.env.CHAT_API_KEY!;
import { AppDispatch } from "redux/store";
import Router from "next/router";
export function Encrypt(values: string) {
  const encJson = CryptoJS.AES.encrypt(
    JSON.stringify(values),
    cryptoSecret
  ).toString();
  const encData = CryptoJS.enc.Base64.stringify(
    CryptoJS.enc.Utf8.parse(encJson)
  );
  return encData;
}
export function Decrypt(values: string | null) {
  const decData = CryptoJS.enc.Base64.parse(
    values == null ? "" : values
  ).toString(CryptoJS.enc.Utf8);
  const bytes = CryptoJS.AES.decrypt(decData, cryptoSecret).toString(
    CryptoJS.enc.Utf8
  );
  return bytes ? JSON.parse(bytes) : {};
}

export const getCurrentUser = () => {
  const params = {
    token: localStorage.getItem(authConfig.storageTokenKeyName) || null,
    currentUser: localStorage.getItem(authConfig.storageUserKeyName)
      ? JSON.parse(Decrypt(localStorage.getItem(authConfig.storageUserKeyName)))
      : null,
  };
  return params;
};

export const setCurrentUser = (data: any) => {
  localStorage.setItem(authConfig.storageTokenKeyName, data.access);
  localStorage.setItem(authConfig.storageRefreshKeyName, data.refresh);
  const userInfo = data ? Encrypt(JSON.stringify(data)) : "";
  localStorage.setItem(authConfig.storageUserKeyName, userInfo);
};
export const setChatToken = (token: string) => {
  localStorage.setItem(authConfig.storageChatTokenKeyName, token);
};
export const getChatToken = () => {
  return localStorage.getItem(authConfig.storageChatTokenKeyName);
};

export const removeCurrentUser = () => {
  localStorage.clear();
  window.location.href = window.location.origin + "/";
};

export const formatError = (errorsResponse: any[]) => {
  let errors: any = {};
  Object.entries(errorsResponse).forEach(([key, value]) => {
    errors = {
      ...errors,
      [key]: value[0],
    };
  });
  return errors;
};

export const callLogOut = async (dispatch: AppDispatch) => {
  removeCurrentUser();
  dispatch({
    type: IS_LOGGED_OUT,
    payload: {},
  });
};

export const callRefreshToken = async () => {
  const res = await refreshTokenApi();
  if (res.data.status == "SUCCESS") {
    localStorage.setItem(authConfig.storageTokenKeyName, res.data.body.access);
  }
  return res.data.status;
};

export const checkResponse = (response: any) => {
  let newResponse: any = {
    code: "",
    success: false,
    data: null,
    message: "",
    errorMessages: [],
    pagination: null,
  };
  try {
    const { data } = response;
    if (
      response.status === 200 ||
      response.status === 201 ||
      response.status === 204
    ) {
      newResponse = {
        code: response.status,
        success: true,
        data: data.body || null,
        image: data || null,
        pagination: data.pagination || null,
        message: data.message,
        errorMessages: [],
      };
    } else {
      newResponse = {
        code: response.status,
        success: false,
        data: null,
        message: data.message,
        errorMessages: data.message,
        pagination: null,
      };
    }
  } catch (error) {
    // toast("Internal Server error, please try after sometime!", {
    //   style: {
    //     borderRadius: "10px",
    //     background: "#333",
    //     color: "#fff",
    //   },
    // });
  }

  return newResponse;
};
export const CapitalizedText = (text: string) =>
  text.slice(0, 1).toUpperCase() + text.slice(1, text.length);

export const FilterFieldType = (label: string) => {
  const FieldTypeForFilter = [
    { type: 4, label: "Diagnoses" },
    { type: 1, label: "Staff" },
    { type: 2, label: "Age" },
    { type: 4, label: "Status" },
    { type: 1, label: "Gender" },
    { type: 5, label: "Programme" },
    { type: 6, label: "Program Start Date" },
  ];
  const type = FieldTypeForFilter.find((item) => item.label == label);
  return type ? type.type : 1;
};

export const genderType = [
  { value: 2, label: "Male" },
  { value: 1, label: "Female" },
  { value: 3, label: "Others" },
];
export const statusTypes = [
  { value: 1, label: "Activated" },
  { value: 2, label: "Invited" },
  { value: 3, label: "Pending" },
  { value: 4, label: "Verified" },
];

export const formatImage = (image: string) => {
  return `data:image/png;base64,${Buffer.from(image, "binary").toString(
    "base64"
  )}`;
};

export const formatPdf = (pdfData: string) => {
  return `data:application/pdf;base64,${Buffer.from(pdfData, "binary").toString(
    "base64"
  )}`;
};

export const getCookie = (cookieName: string) => {
  let cookie: any = {};
  document.cookie.split(";").forEach(function (el) {
    let [key, value] = el.split("=");
    cookie[key.trim()] = value;
  });
  return cookie[cookieName];
};

export const getBrowser = () => {
  let ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: "IE", version: tem[1] || "" };
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem != null) {
      return { name: "Opera", version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: M[1],
  };
};
