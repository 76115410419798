import axios from "axios";
import { restServers } from "./ServersUrl";

export const configureAxiosUrl = () => {
  axios.defaults.baseURL = restServers ? restServers.mainServer : "";
  return restServers.mainServer;
};

export const configureAxiosUrlV2 = () => {
  axios.defaults.baseURL = restServers ? restServers.mainServerV2 : "";
  return restServers.mainServerV2;
};

export const configureAxiosSurveyUrl = () => {
  axios.defaults.baseURL = restServers ? restServers.surveyUrl : "";
  return restServers.surveyUrl;
};
