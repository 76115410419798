
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../scss/style.scss";
import type { AppProps } from "next/app";
import { appWithTranslation } from "next-i18next";
import CommonContext from "Context/CommonContext";
import { Provider } from "react-redux";
import store from "redux/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AxiosErrorHandler from "@services/AxiosErrorHandler/AxiosErrorHandler";
import { useRouter } from "next/router";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as amplitude from "@amplitude/analytics-browser";
import { useEffect } from "react";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1caec9",
      contrastText: "#fff",
    },
    secondary: {
      main: "#50777E",
      contrastText: "#fff",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
      },
    },
  },
});

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    const amplitudeApiKey = process.env.AMPLITUDE_API_KEY || "";
    if (router.isReady) {
      amplitude.init(amplitudeApiKey, {
        defaultTracking: true,
      });
    }
  }, [router.isReady]);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <title>GluCare</title>
        <CommonContext>
          <ToastContainer />
          <Component key={router.asPath} {...pageProps} />
          <AxiosErrorHandler />
        </CommonContext>
      </ThemeProvider>
    </Provider>
  );
}

const __Page_Next_Translate__ = appWithTranslation(MyApp);


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  