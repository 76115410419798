import api from "@services/AxiosConfig/Config";

export const settingListQuery = () => {
  return api.get(`notification/setting/`);
};

export const updateSettingQuery = (data: {
  category: string;
  channel_type: string;
  action: string;
}) => {
  const formData = new FormData();
  formData.append("category", data.category);
  formData.append("channel_type", data.channel_type);
  formData.append("action", data.action);
  return api.post(`notification/setting/`, formData);
};

export const settingEnableAllQuery = () => {
  return api.put(`notification/setting/enable/`);
};

export const settingDisableAllQuery = () => {
  return api.put(`notification/setting/disable/`);
};

export const internalNotificatoinListQuery = (data: {
  page_size: number;
  page: number;
}) => {
  return api.get(
    `notification/internal/?page_size=${data.page_size}&page=${data.page}`
  );
};

export const internalNotificatoinReadQuery = (data: { id: number }) => {
  return api.put(`notification/internal/${data.id}/read/`);
};

export const internalNotificatoinReadAllQuery = () => {
  return api.put(`notification/internal/read/`);
};
