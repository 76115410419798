import { AppDispatch, CustomDispatch } from "redux/store";
import {
  callLogOut,
  callRefreshToken,
  checkResponse,
  formatError,
} from "utils";
import { getChatCategoryQuery } from "./query";

export const ERRORS = "ERRORS";
export const COMPLETE = "COMPLETE";
export const GET_CHAT_CATEGORY = "GET_CHAT_CATEGORY";

export const getChatCategory =
  () => async (dispatch: AppDispatch | CustomDispatch) => {
    const res = await getChatCategoryQuery();
    const response = await checkResponse(res);

    if (response.success) {
      dispatch({
        type: GET_CHAT_CATEGORY,
        payload: response.data,
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };
